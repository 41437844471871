.App {
  background-color: black;
}

.page-element-container {
  height: 100vh; /* Use vh as a fallback */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  box-sizing: border-box;
  border: 9px solid white;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge for hiding scrollbar */
  scrollbar-width: none;  /* Firefox for hiding scrollbar */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page-element-container::-webkit-scrollbar {
  display: none;
}
