html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  background-color: black;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: white;
}

li {
  list-style-type: '﹡ ';
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
