.skills-item {
  grid-column-start: 1;
  grid-column-end: 4;
  border-bottom: 4px;
}

.skills-item, .company, .timing, .title, .bullets {
  display: flex;
  align-items: center;
  padding: 3px 7px;
  color:white;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  font-family: 'Chivo', sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.company, .timing, .title, .skills-item {
  font-size: 25px;
}

.bullets {
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: 1fr;
  text-indent: -27px;
  padding-left: 34px;
  font-weight: 400;
}

.leftmost-column {
  border-left: 0px;
}

.last-row {
  border-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .company, .timing, .title, .skills-item {
    font-size: 22px;
  }

  .bullets {
    display: none;
  }
}

@media only screen and (max-width: 470px) {
  .company, .timing, .title, .skills-item {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .skills-item {
    grid-column-end: 3;
  }

  .timing {
    display: none;
  }
}
