.project-item-data {
  display: flex;
  align-items: center;
  padding: 3px 7px;
  color:white;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  font-family: 'Chivo', sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.leftmost-column {
  border-left: 0px;
}

.last-row {
  border-bottom: 0px;
}

@media only screen and (max-width: 816px) {
  .desc {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .lang {
    display: none;
  }
}
