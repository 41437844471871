.page-element-plaque {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: auto;
  background-color: black;
  cursor: pointer;
}

.page-element-plaque-foot {
  height: 110px;
  cursor: default;
}

.page-element-text {
  color: white;
  width: 100%;
  padding-left: 1%;
  padding-top: .175em;
  padding-bottom: .175em;
  font-family: 'Chivo', sans-serif;
  font-size: 4em;
  font-weight: 700;
}
