.bio-item {
  padding: 3px 7px;
  color:white;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  font-family: 'Chivo', sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.bio-item {
  text-indent: -27px;
  padding-left: 34px;
}

.last-row {
  border-bottom: 0px;
}
