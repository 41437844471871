.page-element-dropdown-container {
  width: auto;
  overflow: hidden;
}

.page-element-dropdown {
  box-sizing: border-box;
  border-top: 9px solid white;
  border-bottom: 9px solid white;
  background-color: black;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge for hiding scrollbar */
  scrollbar-width: none;  /* Firefox for hiding scrollbar */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page-element-dropdown::-webkit-scrollbar {
  display: none;
}

.notDropped {
  height: 9px;
  transition: height .3s ease;
}

.dropped {
  height: 250px;
  transition: height .3s ease;
}

.resume-container.dropped {
  height: 375px;
}

.bio-content {
  display: grid;
  grid-template-columns: 1fr [bio-item];
}

.resume-content {
  display: grid;
  grid-template-columns: 1.8fr [company] 2fr [title] 1.2fr [timing];
}

.github-content {
  display: grid;
  grid-template-columns: .5fr [date] auto [name] 3fr [desc] .5fr [lang];
}

@media only screen and (max-width: 816px) {  
  .github-content {
    grid-template-columns: 1fr [date] 1fr [name] 1fr [lang];
  }
}

@media only screen and (max-width: 600px) {
  .resume-content {
    grid-template-columns: 2fr [company] 2fr [title] 1.2fr [timing];
  }
  
  .github-content {
    grid-template-columns: 1fr [date] 1fr [name];
  }
}

@media only screen and (max-width: 400px) {
  .resume-content {
    grid-template-columns: 1fr 1fr;
  }
}
